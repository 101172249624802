import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/polityka-cookies/" className="sel">
              {" "}
              &gt; Zasady korzystania z plików cookie
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">Zasady korzystania z plików cookie</h1>
            <div className="wysiwyg">
              <p>
                Celem niniejszych Zasad korzystania z plików cookie jest
                prezentacja zasad stosowanych w firmie w kontekście gromadzenia
                informacji za pośrednictwem plików cookie i innych technologii
                śledzenia (np. plików gif, elementów nawigacyjnych web beacon
                itd.).
              </p>
              <p>
                Do funkcjonalności, w których wykorzystujemy te technologie,
                mogą się zaliczać:
              </p>
              <ul>
                <li>
                  <p>Ułatwianie użytkownikom nawigacji,</p>
                </li>
                <li>
                  <p>
                    Ułatwianie użytkownikom dokonania rejestracji na zdarzenia,
                    logowania się i przesyłania opinii,
                  </p>
                </li>
                <li>
                  <p>
                    Analiza sposobu korzystania z naszych produktów, usług lub
                    aplikacji,
                  </p>
                </li>
                <li>
                  <p>
                    Wsparcie działań promocyjno-marketingowych firmy (m.in.
                    reklama behawioralna),
                  </p>
                </li>
                <li>
                  <p>
                    Proponowanie treści niezależnych podmiotów (np. treści w
                    serwisach społecznościowych).
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                Poniżej znajduje się szczegółowa lista wykorzystywanych przez
                nas plików cookie wraz z opisem. Pliki cookie dzielimy na
                następujące kategorie:
              </p>
              <ul>
                <li>
                  <p>Bezwzględnie konieczne</p>
                </li>
                <li>
                  <p>Związane z wydajnością</p>
                </li>
                <li>
                  <p>Funkcjonalne</p>
                </li>
                <li>
                  <p>Ukierunkowujące</p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                Z wyjątkiem przypadków dozwolonych przez obowiązujące prawo,
                pliki cookie są umieszczane na dysku użytkownika dopiero po
                uzyskaniu jego zgody za pośrednictwem banera informującego o
                plikach cookie albo poprzez centrum preferencji. Ustawienia
                plików cookie można w każdej chwili zmienić dla poszczególnych
                kategorii plików cookie (z wyjątkiem plików bezwzględnie
                koniecznych, niezbędnych do prawidłowego funkcjonowania
                witryny), klikając przycisk „Ustawienia plików cookie” (cookie
                settings) poniżej:&nbsp;
              </p>
              <br/>
              <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
              <p>
                <strong>Linki do innych witryn</strong>
              </p>
              <p>
                Witryna może zawierać linki i odnośniki do innych witryn. Należy
                mieć na uwadze, że nie kontrolujemy plików cookie ani
                technologii śledzenia stosowanych w innych witrynach i niniejsze
                Zasady korzystania z plików cookie nie mają do tych witryn
                zastosowania.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Jak się z nami skontaktować?</strong>
              </p>
              <p>
                W razie pytań, uwag lub zastrzeżeń dotyczących niniejszych Zasad
                korzystania z plików cookie lub procedur dotyczących informacji,
                które obowiązują w tej witrynie, należy skorzystać z danych
                kontaktowych podanych w naszej Polityce prywatności.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Zmiany Zasad korzystania z plików cookie</strong>
              </p>
              <p>
                W razie zmiany Zasad korzystania z plików cookie zmieniona
                wersja zostanie opublikowana w tej witrynie. Data ostatniej
                aktualizacji Zasad korzystania z plików cookie: 01.02.2023
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
          <footer>
            <div className="page_width">
              <div className="drug_alert"></div>
              <p
                style={{
                  "font-size": "10px",
                  "-webkit-text-align": "right",
                  "text-align": "right",
                  padding: "0 0 10px",
                }}
              >
                MAT/6769/03/2019
              </p>
            </div>
            <div className="footer_content">
              <div className="page_width">
                <ul id="footer_menu">
                  <li>
                    <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                  </li>
                  <li>
                    <a href="/polityka-cookies/">Polityka cookies</a>
                  </li>
                  <li>
                    <a href="/nota-prawna/">Nota prawna</a>
                  </li>
                  <li>
                    <a href="/mapa-strony/">Mapa strony</a>
                  </li>
                  <li>
                    <a href="/slowniczek/">Słowniczek</a>
                  </li>
                  <li>
                    <a
                      id="ot-sdk-btn"
                      className="ot-sdk-show-settings"
                      rel="nofollow"
                    >
                      {" "}
                      Ustawienia plików Cookie{" "}
                    </a>
                  </li>
                </ul>
                <div className="rel footer_info">
                  <br />
                  Podmiot odpowiedzialny: McNeil Products Limited <br />©
                  JNTL Consumer Health (Poland) sp. z o.o. 2023<br/>
                  JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
                </div>
              </div>
            </div>
          </footer>{" "}
        </div>
      </div>
    );
  }
}

export default Page;
